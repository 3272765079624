<template>
    <div class="login-wrap">
        <div class="content">
            <div class="left">
                <img src="../../assets/images/admin/Login_background.jpg" alt="">
            </div>
            <div class="right">
                <div class="login-form">
                    <div class="title">
                        <span class="welcome-title">一课官网运维系统</span>
                    </div>
                    <el-form :model="loginForm" :rules="rules" ref="loginForm" label-width="100px"
                             :label-position="labelPosition" class="demo-ruleForm">
                        <el-form-item prop="username">
                            <el-input v-model="loginForm.username" placeholder="请输入账号"></el-input>
                        </el-form-item>
                        <el-form-item prop="password">
                            <el-input v-model="loginForm.password" @keyup.enter.native="onLogin('loginForm')"
                                      type="password" placeholder="请输入密码"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button size="medium" class="student-btn" @click="onLogin('loginForm')">登录</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {userLogin} from '@/utils/apis'

    export default {
        name: "AdminLogin",
        data() {
            return {
                labelPosition: 'top',
                loginForm: {
                    username: '',
                    password: ''
                },
                rules: {
                    username: [
                        {required: true, message: '请输入账号', trigger: 'blur'},
                    ],
                    password: [
                        {required: true, message: '请输入密码', trigger: 'blur'}
                    ]
                }
            }
        },
        methods: {
            onLogin(fromName) {
                // console.log(52,fromName)
                this.$refs[fromName].validate((valid) => {
                    if (valid) {
                        let formData = new FormData();
                        formData.append('username', this.loginForm.username);
                        formData.append('password', this.loginForm.password);
                        userLogin(formData).then((res) => {
                            localStorage.setItem('role', 1)
                            localStorage.setItem('adminToken', res.data.token)
                            localStorage.setItem('adminName', res.data.username)
                            this.$message.success(res.msg)
                            this.$router.push('/admin')
                        }).catch((err) => {
                        })
                    } else {
                        return false
                    }
                })
            },
        }
    }
</script>

<style scoped lang="scss">
    .login-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-image: url("../../assets/images/admin/adminLogin_background.png");
        background-repeat: no-repeat;
        overflow: auto;
    }

    .header {
        padding: 20px;
    }

    .content {
        width: 1150px;
        height: 580px;
        background-color: #fff;
        box-shadow: 0px 3px 9px 1px rgba(4, 55, 132, 0.09);
        border-radius: 10px;
        display: flex;
    }

    .left {
        width: 60%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .right {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40%;
        height: 100%;
    }

    .login-form {
        width: 420px;
        height: 400px;
        padding-top: 30px;
        padding-right: 30px;

        .title {
            text-align: center;

            .welcome-title {
                color: #222222;
                font-size: 24px;
            }
        }

        .demo-ruleForm {
            margin-top: 60px;
        }

        /deep/ .el-input__inner {
            font-size: 18px;
            color: #999999;
            height: 50px;
            border-radius: 25px;
            background: #fff;
        }

        /deep/ .el-form-item {
            margin-bottom: 30px;
        }

        /deep/ .el-form-item__error {
            left: 12px;
        }

        .student-btn {
            width: 100%;
            height: 50px;
            background: #20C2C9;
            border-radius: 25px;
            color: #fff;
            border: none;
            font-size: 18px;
            margin-top: 80px;
        }

        .student-btn:hover {
            background: #20C2C9;
        }
    }
</style>